import React from "react"
import { Link } from "gatsby"
import houseS from '../img/house/houses_s.jpg';
import landshaftS from '../img/house/landshaft_s.jpg';
import otdelkaS from '../img/house/otdelka_s.jpg';
import stairsS from '../img/house/stairs.jpg';
import materialsS from '../img/house/materials_s.jpg';
import buildingS from '../img/house/building_s.jpg';
import Layout from "../components/layout"
import SEO from "../components/seo"

const Uslugi = ({ pageContext }) => (
  <Layout pageContext={pageContext}>
    <SEO title="Услуги" />

    <section id="service" className="pb80 pt100 bg-dark uslugi">

      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <div className="section-title mb25">
              <h1>Услуги</h1>
              <p>Сегодня многие застройщики вынуждены обращаться одновременно в несколько разных компаний (например, за строительством, благоустройством, отделкой и т.д.). Это может  привести к снижению качества конечного результата и дополнительным затратам. ООО «СК МАСТЕР» позволит избежать данных проблем.</p>
              <hr />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="canvas-box mb25 text-center">
              <span className="text-center">
                <i className="fa">
                  <Link to='/uslugi/building/'>
                    <img src={houseS} />
                  </Link>
                </i>
              </span>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="canvas-box mb25 text-center">
              <h4 className="color-style2">
                <Link to='/uslugi/building/'>
                  Строительство коттеджей, особняков и прочих малоэтажных объектов любого назначения, включая
                </Link>
              </h4>
              <ul className="list-group checklist">
                <li className="list-group-item"> строительство малоэтажных объектов любого назначения в Москве и Московской области (включая земляные работы, устройство фундаментов и перекрытий, кровельные и фасадные работы, монтаж ограждений, ворот и др.);</li>
                <li>монтажные, пусконаладочные работы и обслуживание инженерных сетей (внешних и внутренних);</li>
                <li>создание ландшафта и благоустройство территории;</li>
                <li>отделочные работы (черновые и финишные);</li>
                <li>изготовление интерьерных лестниц на заказ;</li>
                <li>поставка качественных строительных и отделочных материалов.</li>
              </ul>
            </div>
          </div>
          <div className="col-md-8 col-sm-8">
            <div className="canvas-box  mb25  text-center">
              <span className="text-center">
                <i className="fa">
                  <Link to='/uslugi/engineering/'>
                    <img src={landshaftS} />
                  </Link>
                </i>
              </span>
              <h4 className="color-style2">
                <Link to='/uslugi/engineering/'>
                  Устройство внутренних и внешних инженерных коммуникаций.
                </Link>
              </h4>
              <p>Профессионализм и опыт наших специалистов, а также современное оборудование и высококачественные материалы позволяют нам создавать максимально надежные и долговечные водопроводы, канализации, электросети и слаботочные сети.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="canvas-box mb25  text-center">
              <span className="text-center">
                <i className="fa">
                  <Link to='/uslugi/blagoustroistvo/'>
                    <img src={buildingS} />
                  </Link>
                </i>
              </span>
              <h4 className="color-style2">
                <Link to='/uslugi/blagoustroistvo/'>
                  Благоустройство территорий.
                </Link>
              </h4>
              <p>Мы в силах создать не только комфортный дом или любую другую постройку, но и уютную прилегающую территорию.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="canvas-box  mb25  text-center">
              <span className="text-center">
                <i className="fa">
                  <Link to='/uslugi/otdelka/'>
                    <img src={otdelkaS} />
                  </Link>
                </i>
              </span>
              <h4 className="color-style2">
                <Link to='/uslugi/otdelka/'>
                  Финишная и черновая отделка.
                </Link>
              </h4>
              <p>Специалисты «СК МАСТЕР» доведут любое помещение до идеального состояния, в зависимости от назначения.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="canvas-box  mb25 text-center">
              <span className="text-center">
                <i className="fa">
                  <Link to='/uslugi/stairs/'>
                    <img className={'w100'} src={stairsS} />
                  </Link>
                </i>
              </span>
              <h4 className="color-style2">
                <Link to='/uslugi/stairs/'>
                  Изготовление интерьерных лестниц.
                </Link>
              </h4>
              <p>Эксклюзивный проект лестницы – это визитная карточка загородного дома или офиса уважаемой компании.</p>
            </div>
          </div>
          <div className="col-md-4 col-sm-4">
            <div className="canvas-box  mb25 text-center">
              <span className="text-center">
                <i className="fa">
                  <Link to='/uslugi/supply/'>
                    <img src={materialsS} />
                  </Link>
                </i>
              </span>
              <h4 className="color-style2">
                <Link to='/uslugi/supply/'>
                  Оптовые поставки материалов от надежных производителей и поставщиков.
                </Link>
              </h4>
              <p>Оперативно доставим строительные и отделочные материалы на Ваш объект, чтобы не было простоев в работе.</p>
            </div>
          </div>
        </div>
        <p className='exclamation'>Обращаясь в ООО «СК МАСТЕР», Вы делаете выбор в пользу надежной организации. Мы ценим каждого нашего клиента и предлагаем доступные цены.</p>
      </div>
    </section>

  </Layout>
)

export default Uslugi
